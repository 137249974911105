import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import dayjs from 'dayjs';

import { Query } from '@/types';
import { BaseLayout, Meta } from '@/layouts';
import { DateFormat } from '@/constants';
import { stripTag } from '@/util/string';
import { MediaArticleDetail } from '@/molecules';

type MediaPageProps = {
  data: Pick<Query, 'microcmsMedia'>;
};

const MediaPage: React.FC<MediaPageProps> = ({ data }) => {
  const title = data.microcmsMedia?.title || '';
  const name = data.microcmsMedia?.name || '';
  const position = data.microcmsMedia?.position || '';
  const publishDateString =
    data.microcmsMedia?.publishDate || data.microcmsMedia?.publishedAt || '';
  const mainVisualUrl = data.microcmsMedia?.mainVisual?.url || '';
  const lastVisualUrl = data.microcmsMedia?.lastVisual?.url;
  const body = data.microcmsMedia?.body || '';
  // TODO
  // const credit = data.microcmsMedia?.credit || '';
  const credit = '';

  const strippedBody = useMemo(() => stripTag(body || '').slice(0, 200), [body]);

  const publishDate = useMemo(
    () => dayjs(publishDateString).format(DateFormat.YEAR_MONTH_DATE_JP),
    [publishDateString],
  );

  if (!title || !publishDateString || !mainVisualUrl || !body) return <div>data not exists.</div>;
  return (
    <BaseLayout useHeader>
      <Meta title={title} description={strippedBody} ogImage={mainVisualUrl} />
      <MediaArticleDetail
        title={title}
        position={position}
        name={name}
        publishDate={publishDate}
        mainVisualUrl={mainVisualUrl}
        lastVisualUrl={lastVisualUrl}
        body={body}
        credit={credit}
      />
    </BaseLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    microcmsMedia(slug: { eq: $slug }) {
      title
      position
      name
      body
      publishedAt
      publishDate
      mainVisual {
        url
      }
      lastVisual {
        url
      }
    }
  }
`;

export default MediaPage;
